<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" width="1350" persistent>
      <v-card class="pa-5">
        <v-card-title>
          <v-row>
            <v-col cols="">
              <div class="d-flex">
                <h2 :class="color">
                  Add {{ is_invoice == 1 ? "Invoice" : "Bill" }}
                </h2>
                <v-spacer></v-spacer>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Search "
                  v-model="customer_search"
                ></v-text-field>
                <p>Account Name:</p>
                <p class="green--text">
                  {{ selected_account.customer_name }}
                </p>
              </div>
            </v-col>

            <v-col cols="8">
              <v-data-table
                :search="customer_search"
                :items="customers"
                :headers="customer_headers"
                :options="{ itemsPerPage: 3 }"
              >
                <template v-slot:item.action="{ item }">
                  <v-btn small color="primary" @click="select_account(item)"
                    >select</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <!-- ITEMS TABLE -->
            <div :key="selected_payment_type">
              <div class="d-flex">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Purpose"
                      prepend-icon="mdi-pen"
                      v-model="purpose"
                    ></v-text-field
                  ></v-col>
                  <v-col>
                    <v-text-field
                      label="Search"
                      prepend-icon="mdi-magnify"
                      v-model="item_search"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </div>

              <v-data-table
                v-model="payables"
                checkbox-color="success"
                show-select
                :items="items"
                :headers="item_headers"
                :search="item_search"
                :options="table_options"
                @input="get_item_total"
              >
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-text-field
                      dense
                      label="Quantity"
                      type="number"
                      class="mr-4"
                      ref="quantity"
                      v-model="item.quantity"
                      @input="calc(item)"
                    ></v-text-field>
                  </div>
                </template>

                <template v-slot:item.warr="{ item }">
                  <p v-if="item.warranty" class="">
                    {{ item.warranty.warranty_count }}
                    {{ item.warranty.warranty_duration }}
                  </p>
                  <p v-else>No Warranty Issued</p>
                </template>
              </v-data-table>
            </div>

            <!-- ITEM PAYABLES -->
            <div>
              <p class="green--text">Item Payables</p>
              <v-data-table :items="payables" :headers="payable_headers" dense>
                <template v-slot:item.description="{ item }">
                  <span v-if="item.service_name">{{ item.service_name }}</span>
                  <span v-else>{{ item.item_name }}</span>
                </template>

                <template v-slot:item.amount="{ item }">
                  <span v-if="item.service_amount">{{
                    item.service_amount
                  }}</span>
                  <span v-else>{{ item.selling_price }}</span>
                </template>
              </v-data-table>
            </div>

            <!-- TOTAL -->
            <v-row>
              <v-col cols="3" class="mt-3">
                <!-- <v-btn
                  class="mr-2"
                  color="warning"
                  @click="get_item_total()"
                  total
                  small
                  >Recalculate</v-btn
                > -->
                <v-btn
                  total
                  color="yellow"
                  @click="calculate_cheque_amount()"
                  class="mr-2"
                  >Cheque</v-btn
                >
                <v-btn total color="success" @click="add_vat()" class="mr-2"
                  >Vat</v-btn
                >
                <v-btn class="mr-2" total color="" @click="non_vat()"
                  >Non-Vat</v-btn
                >
              </v-col>
              <v-col class="d-flex">
                <v-text-field
                  label="Amount"
                  prepend-icon="mdi-cash"
                  readonly
                  v-model="partial_amount"
                ></v-text-field>
                <v-text-field
                  label="Discount"
                  prepend-icon="mdi-percent"
                  v-model="discount"
                  @input="calculate_discount()"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-cash-100"
                  readonly
                  class="ml-2"
                  type="number"
                  label="Output VAT"
                  v-model="vat"
                ></v-text-field>
                <v-text-field
                  label="Total Amount"
                  prepend-icon="mdi-cash-multiple"
                  readonly
                  :value="total_amount"
                  ref="total_amount"
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="d-flex">
              <p class="mt-3 mr-2">Invoice Date:</p>
              <input
                type="date"
                v-model="invoice_date"
                style="color: "
                class="mr-5"
              />
              <v-btn color="primary" @click="submit" :loading="loading"
                >Submit</v-btn
              >
              <v-btn color="" class="mr-2 ml-2" @click="reset()">Reset</v-btn>
              <v-btn color="" @click="dialog = false">Close</v-btn>
              <v-spacer></v-spacer>
              <p class="caption grey--text">addInvoiceCard.vue</p>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["is_invoice"],
  components: {},
  data() {
    return {
      selected_account: { customer_name: "N/A" },
      customer_search: "",
      customer_headers: [
        { text: "Action", value: "action" },
        { text: "Account Name", value: "customer_name" },
        { text: "Date Created", value: "created_at" },
      ],
      // service
      purpose: "N/a",
      table_options: { itemsPerPage: 3 },
      dialog: false,
      loading: false,
      partial_amount: 0,
      total_amount: 0,

      inputted_quantity: 0,

      // item
      item_payables: [],
      item_search: "",
      item_headers: [
        { text: "", value: "actions", width: 130 },
        { text: "Batch", value: "import_batch.batch_description" },
        { text: "Item Name", value: "item_name" },

        { text: "Item Type", value: "item_type.item_type" },
        { text: "Amount", value: "selling_price" },
        { text: "Total Amount", value: "total_amount" },
        { text: "Quantity", value: "quantity" },
        { text: "Account Title", value: "chart_of_account.account_title" },
      ],
      vat: 0,
      invoice_date: "",
      discount: 0,
      payables: [],
      payable_headers: [
        { text: "id", value: "id" },
        { text: "Description", value: "description" },
        { text: "Amount", value: "amount" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "total_amount" },
      ],
      payment_type: ["Service", "Item"],
      selected_payment_type: "Items",
      item_count: 3,
    };
  },
  methods: {
    reset() {
      this.payables = [];
    },
    calculate_cheque_amount() {
      var vatable = this.total_amount / 1.12;
      var six_percent = vatable * 0.06;
      var total_cheque_amount = vatable + six_percent;

      this.total_amount = total_cheque_amount;
    },
    click(row) {
      console.log(row);
    },
    select_account(item) {
      this.selected_account = item;
      return item;
    },

    add_vat() {
      var vat = this.total_amount * 0.12;
      this.total_amount += vat;
      this.vat = vat;
      return;
    },

    non_vat() {
      this.get_item_total();
      this.vat = 0;
      return;
    },

    ...mapActions({
      add_invoice: "invoice/add_invoice",
      set_notification: "notification/set_notification",
      get_customers: "customer/get_customers",
    }),
    calculate_discount() {
      this.total_amount = this.partial_amount - this.discount;
    },
    calc(item) {
      item.total_amount = item.selling_price * item.quantity;
      var index2 = this.items.findIndex((a) => a.id == item.id);
      this.items.splice(index2, 1, item);
      this.get_item_total();
    },

    get_item_total() {
      let item_total = 0;
      this.payables.forEach((i) => {
        item_total += i.total_amount;
      });
      this.partial_amount = item_total;
      this.calculate_discount();
      return item_total;
    },

    async submit() {
      this.loading = true;

      let form = {
        customer_id: this.selected_account.id,
        discount: this.discount,
        amount: this.partial_amount,
        total_amount: this.total_amount,
        invoice_date: this.invoice_date,
        is_invoice: this.is_invoice,
        payables: this.payables,
        purpose: this.purpose,
        inserted_by: this.user.full_name,
        vat: this.vat,
      };
      // check if empty
      if (
        !form.amount ||
        !form.total_amount ||
        !form.invoice_date ||
        !form.customer_id
      ) {
        this.loading = false;
        return alert("Please check all fields");
      }
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        await this.add_invoice(form).catch((e) => {
          return alert(e);
        });

        this.loading = false;
        return this.set_notification({
          message: "You have successfully inserted a new data",
          type: "success",
        });
      }
    },
    formatted_warranty(service) {
      let duration = service.warranty.warranty_duration;
      let count = service.warranty.warranty_count;
      return count + " " + duration;
    },
  },
  created() {
    this.invoice_date = moment().format("Y-MM-DD");
    if (this.customers.length <= 0) this.get_customers();
  },
  computed: {
    color() {
      if (this.is_invoice) return "primary--text";
      else return "error--text";
    },
    latest_invoice() {
      return this.invoices[0];
    },
    ...mapGetters({
      customers: "customer/customers",
      items: "item/items",
      services: "service/services",
      invoices: "invoice/invoices",
      user: "auth/user",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
